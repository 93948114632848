import {
    Anchor,
    Box,
    Grommet,
    Heading,
    Page,
    PageContent,
    Paragraph,
    Spinner,
    Text
} from "grommet";
import { hpe } from 'grommet-theme-hpe';
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

const NODE_ENV = process.env.NODE_ENV || 'production';


export default function App() {
    return (
        <Grommet theme={hpe} background="background-back" themeMode='auto'>
            <Box style={{ minHeight: '100vh' }}>
                <Routes>
                    <Route index element={<Loader />} />
                    <Route path="unauthorized" element={<Unauthorized />} />
                    <Route path="unauthorized-hpe" element={<UnauthorizedHPE />} />
                    <Route path="error" element={<ErrorElement />} />
                    <Route path='*' element={<NoMatch />} />
                </Routes>
            </Box>
        </Grommet>
    );
}

const Loader = () => {
    React.useEffect(() => {
        if (NODE_ENV === 'development') return window.location.replace('http://' + window.location.hostname + ':3001/api/registration');
        window.location.replace(window.location.origin + '/api/registration');
    }, []);

    return (
        <Box align='center' justify="center" fill flex>
            <Spinner />
        </Box>
    );
};

const ErrorElement = () => {
    const { search } = useLocation();

    React.useEffect(() => {
        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.setAttribute('content', 'noindex');
        document.head.appendChild(metaRobots);
        return () => {
            metaRobots.remove();
        };
    }, []);

    const error_code = React.useMemo(() => {
        if (!search) return [null, null];
        const s = new URLSearchParams(search);
        const c = s.get('code');
        return c;
    }, [search]);

    return (
        <Page>
            <PageContent pad='large'>
                <Box align="center" alignSelf="center" pad={{ top: '10vh' }} width={{ max: '25em', width: '100%' }}>
                    <Heading level={1} margin={{ vertical: 'medium' }} textAlign="center">Login error</Heading>
                    <Paragraph margin="none" textAlign="center">An error has occurred with your login. You may want to try again or contact the <Anchor target="_blank" href="mailto:Education%20DLF%20Curator%20WW%20%3Ceducation.dlf.curator.ww@hpe.com%3E?subject=HPE%20Digital%20Learner%20%26%20Tech%20Pro%20Support" color='brand'>HPE Education support team</Anchor> if you are unsuccessful.</Paragraph>
                    {error_code ? <Text size='xsmall' color='text-weak'>[{error_code}]</Text> : null}
                </Box>
            </PageContent>
        </Page>
    );
}

const Unauthorized = () => {
    React.useEffect(() => {
        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.setAttribute('content', 'noindex');
        document.head.appendChild(metaRobots);
        return () => {
            metaRobots.remove();
        };
    }, []);

    return (
        <Page>
            <PageContent pad='large'>
                <Box align="center" alignSelf="center" pad={{ top: '10vh' }} width={{ max: '25em', width: '100%' }}>
                    <Heading level={1} margin={{ vertical: 'medium' }} textAlign="center">HPE Tech Pro membership level 4 or 5 is required for this benefit.</Heading>
                    <Paragraph margin="none" textAlign="center">Please check your member profile by logging into HPE Tech Pro and reviewing your status. Level 4 members must hold a current HPE Master ASE or HPE Aruba Networking Expert certification along with two current continuous learning badges. Level 5 members are approved and assigned HPE and Partner Ambassadors. If you have questions regarding your membership level or requirements, please <Anchor target="_blank" href="https://certification-learning.hpe.com/tr/support" color='brand'>contact the support team</Anchor>.</Paragraph>
                </Box>
            </PageContent>
        </Page>
    );
};

const UnauthorizedHPE = () => {
    React.useEffect(() => {
        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.setAttribute('content', 'noindex');
        document.head.appendChild(metaRobots);
        return () => {
            metaRobots.remove();
        };
    }, []);

    return (
        <Page>
            <PageContent pad='large'>
                <Box align="center" alignSelf="center" pad={{ top: '10vh' }} width={{ max: '25em', width: '100%' }}>
                    <Heading level={1} margin={{ vertical: 'medium' }} textAlign="center">Authorized, but...</Heading>
                    <Paragraph margin="none" textAlign="center">You are entitled to Digital Learner access, but not via this route. Please register <Anchor target='_blank' href="https://hpe.percipio.com/channels/0b05f6ce-abab-45d3-a0f3-7824eaaa7dd2" color='brand'>here</Anchor>.</Paragraph>
                </Box>
            </PageContent>
        </Page>
    );
};

const NoMatch = () => {
    React.useEffect(() => {
        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.setAttribute('content', 'noindex');
        document.head.appendChild(metaRobots);
        return () => {
            metaRobots.remove();
        };
    }, []);

    return (
        <Page>
            <PageContent pad='large'>
                <Box align="center" width="100%" pad={{ top: '10vh' }}>
                    <Heading level={1} margin={{ vertical: 'medium' }}>Not Found</Heading>
                    <Text textAlign="center">The page you were looking for does not exist!</Text>
                </Box>
            </PageContent>
        </Page>
    );
};
